import L from 'leaflet';
import "leaflet.markercluster";
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

var Lmap;
var mapSelector = document.querySelector('[data-js-map]');

export function map(mapWithoutEventListener = false) {
  mapSelector.classList.add('maps')

  var tiles = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png');

  L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
  
  Lmap = L.map( mapSelector, {
    layers: [tiles],
    zoomControl: false,
    gestureHandling: true
  });
  Lmap.scrollWheelZoom.disable();

  L.control.zoom({
    position: 'bottomright'
  }).addTo(Lmap);

  const mkMarker = function(lat, lng, item, popup, featured, name) {
    if (!!item.getAttribute('data-thumbnail')) {
      var html = `<div style="background-image: url(${item.getAttribute('data-thumbnail')});" class="maps__img"><span class="visually-hidden">${name}</span></div>`
    } else {
      var html = `<div style="background-image: url();" class="maps__img"><span class="visually-hidden">${name}</span></div>`
    }

    const m = L.marker(new L.LatLng(lat, lng), {
      icon: L.divIcon({
        html: html,
        iconSize: [48, 48],
        iconAnchor: [24, 48],
      }), interactive: true
    })
    .bindPopup(popup)
    .on("popupclose", function () {
      document.querySelectorAll('.leaflet-marker-icon').forEach((value) => {
        value.classList.remove('active');
      });
    })
    .on('click', (e) => {
      document.querySelectorAll('.leaflet-marker-icon').forEach((value) => {
        if (value != !e.target.getElement()) {
          value.classList.remove('active');
        }
      });

      e.target.getElement().classList.add('active');
    })
    .on('popupopen', function (e) {
      // if (window.matchMedia("(min-width: 992px)").matches) {
      //   const popupPixelCoords = Lmap.project(e.popup.getLatLng());
      //   popupPixelCoords.y -= 30; 
      //   Lmap.panTo(Lmap.unproject(popupPixelCoords), { animate: true });
      // }
    })
    .on("add", (e) => {
      if (featured === 'true') {
        e.target.getElement().classList.add('active');
        Lmap.openPopup(popup)
      }
    })

    return m;
  }

  var oldClusters;
  var oldFeature;
  var newFeature;
  var mapArray = [];

  window.addEventListener("mapRefresh", () => refresh());

  if (mapWithoutEventListener) {
    refresh()
  }

  function refresh() {
    var listCoordinatesSelector = document.querySelector('[data-js-coordinates]');
    const dataItems = listCoordinatesSelector.querySelectorAll('[data-gps]');

    if (oldClusters || oldFeature) {
      Lmap.removeLayer(oldClusters);
      Lmap.removeLayer(oldFeature);
    }

    var newClusters = L.markerClusterGroup({
      showCoverageOnHover: false
    });
    
    dataItems.forEach((dataItem) => {
      var coord = dataItem.getAttribute('data-gps').replace(',', '').split(' ');
      var featured = dataItem.getAttribute('data-featured');
      var name = dataItem.getAttribute('data-name');
      var dataItemContent = dataItem.innerHTML
      var popup = L.popup({
        closeButton: false,
        minWidth: 0,
        maxWidth: "auto",
        maxHeight: "auto",
        offset: L.point(0, -48),
      })
      .setLatLng([coord[0], coord[1]])
      .setContent(dataItemContent)

      var marker;

      marker = mkMarker(coord[0], coord[1], dataItem, popup, featured, name);
      newClusters.addLayer(marker);

      if (featured === 'true') {
        newFeature = marker
        newClusters.removeLayer(newFeature);
        Lmap.addLayer(newFeature);
      }

      Lmap.addLayer(newClusters);
      mapArray.push(marker);
    });

    var newLayer = L.featureGroup(mapArray);

    if (newClusters.getLayers().length) {
      Lmap.fitBounds(newLayer.getBounds(), {
        padding: [150, 150]
      });
    }

    oldClusters = newClusters;
    oldFeature = newFeature;
  }
}