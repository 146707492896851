import { map } from '@scripts/map';
if (document.querySelector('#js-residences-filters')) {
    map();
    filtersResidences();
}

function filtersResidences() {
    document.getElementById('js-search-bar').addEventListener('input', function() {
        handleSearch();
    });

    const checkboxes = {
        'sales_type': document.querySelectorAll('input[type="checkbox"][name="sales_type"]'),
        'sales_type_size': document.querySelectorAll('input[type="checkbox"][name="sales_type_size"]'),
        'purchase_assistance': document.querySelectorAll('input[type="checkbox"][name="purchase_assistance"]'),
        'residence_type': document.querySelectorAll('input[type="checkbox"][name="residence_type"]')
    };

    function handleCheckboxChange() {
        var search_query = document.getElementById('js-search-bar').value;
        const selectedItems = {
            sales_type: getSelectedItems('sales_type'),
            sales_type_size: getSelectedItems('sales_type_size'),
            purchase_assistances: getSelectedItems('purchase_assistance'),
            residence_type: getSelectedItems('residence_type'),
            search_query: search_query
        };
        fetchResidences(selectedItems);
    }

    Object.values(checkboxes).forEach(checkboxGroup => {
        checkboxGroup.forEach(checkbox => {
            checkbox.addEventListener('change', handleCheckboxChange);
        });
    });

    handleCheckboxChange();
}

function handleSearch(clicked = false) {
    const searchQuery = document.getElementById('js-search-bar').value.trim().toLowerCase();
    var residencesFilters = document.getElementById('js-residences-filters');
    var postType = residencesFilters.dataset.postType;

    if (searchQuery.length >= 2) {
        jQuery.ajax({
            url: document.getElementById('js-residences-filters').dataset.ajaxurl,
            type: 'POST',
            data: {
                action: 'get_search_suggestions',
                post_type: postType,
                search_query: searchQuery
            },
            success: function(response) {
                jQuery('#search-suggestions').html(response);

                if (clicked) {
                    document.querySelector('#search-suggestions').innerHTML = ""
                }
            }
        });
    }

    const selectedItems = {
        sales_type: getSelectedItems('sales_type'),
        sales_type_size: getSelectedItems('sales_type_size'),
        purchase_assistances: getSelectedItems('purchase_assistance'),
        residence_type: getSelectedItems('residence_type'),
        search_query: searchQuery 
    };
    
    fetchResidences(selectedItems);
}

function fetchResidences(selectedItems) {
    var residencesFilters = document.getElementById('js-residences-filters');
    var residencesResults = document.getElementById('residences-results');
    var postType = residencesFilters.dataset.postType;
    
    const data = new FormData();
    data.append('action', '_emeraude_get_residences');
    data.append('post_type', postType);
    data.append('sticky_residence', residencesResults.dataset.sticky);
    data.append('selected_filters', JSON.stringify(selectedItems));

    fetch(document.getElementById('js-residences-filters').dataset.ajaxurl, {
        method: 'POST',
        body: data
    })
    .then(response => response.json())
    .then(data => {
        var listing_residences = document.getElementById('residences-results');
        listing_residences.innerHTML = data;

        const mapRefresh = new CustomEvent("mapRefresh");
        window.dispatchEvent(mapRefresh);
    })
    .catch(error => {
        console.error('Error:', error);
    });
}


function getSelectedItems(checkboxName) {
    const checkboxes = document.querySelectorAll(`input[type="checkbox"][name="${checkboxName}"]:checked`);
    return Array.from(checkboxes).map(checkbox => checkbox.value);
}

jQuery(document).ready(function($) {
    $(document).on('click', '.search-suggestion', function() {
        var suggestion = $(this).text();
        $('#js-search-bar').val(suggestion);
        
        var normalizedSuggestion = suggestion.replace(/\bst\b/gi, 'saint');
        $('#js-search-bar').val(normalizedSuggestion);
        
        handleSearch(this); 
    });
});